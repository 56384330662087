
import history from '@iso/lib/helpers/history';
import Parser from 'html-react-parser';
const utilities = {
  convertToPoints: (val) => {
    if (val && !val.toString().includes("pt")) {
      val = val + "pt";
      return val;
    }
    return val;
  },
  convertObjToPoints: (obj) => {
    if (obj) {
      let newObj = {
        ...obj,
        paddingLeft: obj.paddingLeft
          ? utilities.convertToPoints(obj.paddingLeft)
          : 0,
        paddingRight: obj.paddingRight
          ? utilities.convertToPoints(obj.paddingRight)
          : 0,
        paddingBottom: obj.paddingBottom
          ? utilities.convertToPoints(obj.paddingBottom)
          : 0,
        paddingTop: obj.paddingTop
          ? utilities.convertToPoints(obj.paddingTop)
          : 0,
        fontSize: obj.fontSize
          ? utilities.convertToPoints(obj.fontSize)
          : "16pt",
      };
      if(obj.hidden === true)
        newObj.display = "none";
      return newObj;
    }
  },
  displayPrice: (title) => {
    if (title.TitlePrice) {
      let currency = "";
      if (title.CurrencySymbol) currency = title.CurrencySymbol;
      if (title.TitlePrice > 0)
        return currency + Number(title.TitlePrice).toFixed(2);
      else return "Free";
    }
  },
  goBack: () => {
    history.goBack();
  },
  shortTitleName: (name) => {
    if (typeof name !== 'string' || name.trim() === '') {
      return ''; 
    }
    return encodeURI(name.replaceAll(" ", "-").replaceAll("/", "-").substring(0, 60));
  },
  msToTime: (duration) => {
    var seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    if (hours > 0) {
      return hours + "h " + minutes + "m " + seconds + "s";
    } else {
      return minutes + "m " + seconds + "s";
    }
  },
  pad: (n) => {
    return n < 10 ? "0" + n : n;
  },
  msToTimeNumberFormat: (duration) => {
    var seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    if (hours > 0) {
      return (
        utilities.pad(hours) +
        ":" +
        utilities.pad(minutes) +
        ":" +
        utilities.pad(seconds)
      );
    } else {
      return utilities.pad(minutes) + ":" + utilities.pad(seconds);
    }
  },
  secondsToHms: (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    var hDisplay = h > 0 ? String(h).padStart(2, "0") + ":" : "";
    var mDisplay = String(m).padStart(2, "0") + ":";
    var sDisplay = String(s).padStart(2, "0");
    return hDisplay + mDisplay + sDisplay;
  },
  msToHms: (duration) => {
    var seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    var hDisplay = hours > 0 ? String(hours).padStart(2, "0") + ":" : "";
    var mDisplay = String(minutes).padStart(2, "0") + ":";
    var sDisplay = String(seconds).padStart(2, "0");
    return hDisplay + mDisplay + sDisplay;
  },
  getParsedValue: (value) => {
    //parsing it twice as the description comes as  &lt;p&gt instead of <p>
    if (value) return Parser(value);
    else return "";
  },
  htmlDecode: (input) => {
    var e = document.createElement("textarea");
    e.innerHTML = input;
    // handle case of empty input
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  },

  stringInBetween: (whole_str, str1, str2) => {
    if (whole_str === undefined) return undefined;
    if (whole_str.indexOf(str1) === -1 || whole_str.indexOf(str2) === -1) {
      return undefined; // or ""
    }
    const strlength1 = str1.length;
    return whole_str.substring(
      whole_str.indexOf(str1) + strlength1,
      whole_str.indexOf(str2)
    );
  },
  pluralize: (number, text) => {
    if (text === "Credit") {
      const num = parseFloat(number).toString(); //2.00 becomes 2 and 2.5 as 2.5
      if (number > 1) return `${num} ${text}s`;
      else return `${num} ${text}`;
    } else return `${number} ${text}`;
  },
  validEmail: (email) => {
    const emailRegex = RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (emailRegex.test(email)) return true;
    return false;
  },
  getQueryParam: (queryString, param) => {
    const queryParams = new URLSearchParams(queryString);
    let value = '';

    for (const [key, val] of queryParams) {
      if (key.toLowerCase() === param.toLowerCase()) {
        value = val;
        break;
      }
    }

    return value;
  },
  validateRedirect: (props) => {
    if(props.ShopifyShop){
      window.location.href = `https://${props.ShopifyShop}`;
      return;
    }
  },
  getTitleType: (type) => {
    if(type){
    switch (type.toLowerCase()) {
      case "all":
        return 0;
      case "ebook":
        return 1;
      case "audio":
        return 2;
      case "interactive":
        return 3;
      case "video":
        return 4;
      default:
        return 0; // Or any other fallback value you prefer
    }
  }
  return 0;
  },
  htmlDecode: (input) => {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }   
};
export default utilities;
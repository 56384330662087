import { PUBLIC_ROUTE, PRIVATE_ROUTE } from '../../route.constants';

const actions = {
  TOPBAR_BUTTON_VISIBILITY_CHANGE: 'TOPBAR_BUTTON_VISIBILITY_CHANGE',
  CHANGE_TOPBAR_FILTER_VALUE: 'CHANGE_TOPBAR_FILTER_VALUE',
  UPDATE_CART: 'UPDATE_CART',
  changeTopbarButtonVisibility: ({hideSidebar, hideSubscribe, hideSearch, hideLibrary, hideHome, hideSignin, VideoListImg, TitleID, VideoListTitle, VideoList, isSample, hideTopbar,hideTopFilter, hideFooter,hideAccount, hideExplore, showOpenApp }) => ({
    type: actions.TOPBAR_BUTTON_VISIBILITY_CHANGE,
    hideSidebar,
    hideSubscribe,
    hideSearch,
    hideLibrary,
    hideHome,
    hideSignin,
    VideoListImg,
    isSample,
    TitleID,
    VideoListTitle,
    VideoList,
    hideTopbar,
    hideTopFilter,
    hideFooter: hideFooter === undefined? hideTopbar : hideFooter, 
    hideAccount, 
    hideExplore,
    showOpenApp
  }),
  changeTopbarFilterValue: (topBarFilterValue) => ({
    type: actions.CHANGE_TOPBAR_FILTER_VALUE,
    topBarFilterValue
  })
};

function updateCart(count){
  return function(dispatch){
    dispatch({
      type : actions.UPDATE_CART,
      cartCount: count
    });
  }
}

function getTopBarFilerValueFromPath(pathname) {
  switch(pathname) {
    case PUBLIC_ROUTE.ALL_AUDIO_TITLES:
    case PRIVATE_ROUTE.MYLIBRARY_AUDIO:
    case PUBLIC_ROUTE.CATEGORY_AUDIO_TITLES:
      return "audio";
    case PUBLIC_ROUTE.CATEGORY_EBOOK_TITLES: 
    case PRIVATE_ROUTE.MYLIBRARY_EBOOK:
    case PUBLIC_ROUTE.ALL_EBOOK_TITLES:
      return "ebook";
    case PUBLIC_ROUTE.CATEGORY_VIDEO_TITLES:
    case PRIVATE_ROUTE.MYLIBRARY_VIDEO:
    case PUBLIC_ROUTE.ALL_VIDEO_TITLES:
      return "video";
    default:
      return null;
  }
}

function getButtonVisibilityFromPath(pathname) {
  switch(pathname) {
    case PUBLIC_ROUTE.SUBSCRIPTION:
    case PUBLIC_ROUTE.TITLE_PURCHASE:
      return({hideSidebar: true, hideSubscribe: true, hideSearch: true, hideLibrary: true, hideHome: true, VideoList: false, hideTopbar: false,hideTopFilter: true, hideExplore: true});
    case PRIVATE_ROUTE.PAYMENT:
    case PRIVATE_ROUTE.SUBSCRIPTION_PURCHASE_SUCCESS:
    case PRIVATE_ROUTE.SUBSCRIPTION_CHECKOUT_COMPLETE:
    case PRIVATE_ROUTE.TITLE_PURCHASE_SUCCESS:
    case PRIVATE_ROUTE.CART_PURCHASE_SUCCESS:
      return({hideSidebar: true, hideSubscribe: true, hideSearch: true, hideLibrary: true, hideHome: true, VideoList: false, hideTopbar: false,hideTopFilter: true, hideExplore: true});
    case PRIVATE_ROUTE.MYLIBRARY:
    case PRIVATE_ROUTE.MYLIBRARY_BOOK_TYPE:
    case PRIVATE_ROUTE.MYLIBRARY_AUDIO:
    case PRIVATE_ROUTE.MYLIBRARY_EBOOK:
    case PRIVATE_ROUTE.MYLIBRARY_VIDEO:
      return({hideSidebar: true, hideSubscribe: true, hideSearch: false, hideLibrary: false, hideHome: false, VideoList: false, hideTopbar: false,hideTopFilter: false, showOpenApp: true});
    case PUBLIC_ROUTE.ABOUT:
      return({ hideSidebar: true, hideSubscribe: false, hideSearch: true, hideLibrary: false, hideHome: false, hideSignin: false, VideoList: false, hideTopbar: false,hideTopFilter: true });
    case PRIVATE_ROUTE.ACCOUNT:
      return({ hideSidebar: true, hideSubscribe: true, hideSearch: true, hideLibrary: false, hideHome: false, hideSignin: false, VideoList: false, hideTopbar: false ,hideTopFilter: true, hideAccount : false});
    case PUBLIC_ROUTE.PRIVACY:
      return({hideSidebar: true, hideSubscribe: true, hideSearch: true, hideLibrary: true, hideHome: true, hideSignin: true, VideoList: false, hideTopbar: false,hideTopFilter: true});
    case PUBLIC_ROUTE.TERMS:
      return({hideSidebar: true, hideSubscribe: true, hideSearch: true, hideLibrary: true, hideHome: true, hideSignin: true, VideoList: false, hideTopbar: false,hideTopFilter: true});
    case PUBLIC_ROUTE.PUBLIC_PAGE:
    case PUBLIC_ROUTE.PRIVATE_PAGE:
      return({hideSignin:true,hideSidebar: true, hideSubscribe: true, hideSearch: true, hideLibrary: true, hideHome: true, VideoList: false, hideTopbar: false,hideTopFilter: true, hideExplore: true});
    case PRIVATE_ROUTE.VIEWER:
      return({hideSidebar: true, hideFooter: true, hideSubscribe: true, hideSearch: true, hideLibrary: false, hideHome: true, hideSignin: true, VideoListImg: null, isSample: false, TitleID: null, VideoListTitle: null,hideTopFilter: true, hideExplore: true, showOpenApp: true});
    case PRIVATE_ROUTE.PDF_VIEWER:
      return({VideoList: false, hideFooter: true, hideTopbar: true, hideSidebar: true, hideSubscribe: true, hideSearch: true, hideLibrary: false, hideHome: true, hideSignin: true, VideoListImg: null, isSample: false, TitleID: null, VideoListTitle: null,hideTopFilter: true, hideExplore: true, showOpenApp: true});
    case PUBLIC_ROUTE.REDEEM:
    case PUBLIC_ROUTE.REDEEM_CODE:
      return({hideSidebar: true, hideSubscribe: true, hideSearch: true, hideLibrary: false, hideHome: false, hideSignin: true, VideoListImg: null, isSample: false, TitleID: null, VideoListTitle: null,hideTopFilter: true});
    case PUBLIC_ROUTE.LANDING:
      return({hideSidebar: false, hideSubscribe: false, hideSearch: false, hideLibrary: false, hideHome: true, hideSignin: false, VideoListImg: null, isSample: false, TitleID: null, VideoListTitle: null, VideoList: false, hideTopbar: false,hideTopFilter: true, hideExplore: false});
    case PUBLIC_ROUTE.TITLE_DETAILS:
    case PUBLIC_ROUTE.TITLE_DETAILS_ISBN:
      return({hideSidebar: true, hideSubscribe: false, hideSearch: true, hideLibrary: false, hideHome: false, hideSignin: false, VideoListImg: null, isSample: false, TitleID: null, VideoListTitle: null, VideoList: false, hideTopbar: false,hideTopFilter: true, hideExplore: false});
    case PUBLIC_ROUTE.CAMPAIGN:
    case PUBLIC_ROUTE.CART_PURCHASE:
    case PRIVATE_ROUTE.CART_CHECKOUT:
      return({hideSidebar: true, hideSubscribe: false, hideSearch: true, hideLibrary: false, hideHome: false, VideoList: false, hideTopbar: false,hideTopFilter: true, hideExplore: true});
    case PUBLIC_ROUTE.CATELOG_TITLES:
    case PUBLIC_ROUTE.TITLESET_TITLES:
        return({hideSidebar: false, hideSubscribe: true, hideTopFilter: true});
    default:
      return({hideSidebar: false, hideSubscribe: false, hideSearch: false, hideLibrary: false, hideHome: false, hideSignin: false, VideoListImg: null, isSample: false, TitleID: null, VideoListTitle: null, VideoList: false, hideTopbar: false,hideTopFilter: false, hideExplore: false, showOpenApp: false});
  }
}

export default actions;

export {getButtonVisibilityFromPath, getTopBarFilerValueFromPath, updateCart};
